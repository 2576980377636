<template>
  <v-content>
    <v-container class="fill-height" fluid>
      <v-overlay v-model="showCargando">
        <v-progress-circular :size="100" :width="10" color="#311def" indeterminate></v-progress-circular>
        <div>Procesando...</div>
      </v-overlay>
      <v-row row wrap align="center" class="fill-height" align-center fluid justify="center">
        <v-col cols="12" sm="8" md="4">
          <div class="pb-10" align="center">
            <v-img src="https://rayoapp.com/img/2020/logo_celeste.png" max-width="200px"></v-img>
          </div>

          <v-card class="elevation-12">
            <v-toolbar color="#1baefc" dark flat>
              <v-toolbar-title>Inicia Sesión</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form>
                <!-- <v-text-field
                  v-model="form.email"
                  label="Email"
                  name="Email"
                  prepend-icon="mdi-account"
                  type="email"
                  color="#1baefc"
                /> -->

                <!-- <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="form.password"
                  color="#1baefc"
                /> -->
                <v-progress-linear v-if="cargaLogin" indeterminate color="teal"></v-progress-linear>
              </v-form>
            </v-card-text>
            <v-alert class="mx-5 font-weight-light" v-if="error" border="top" color="red lighten-2" dark>{{ error
            }}</v-alert>
            <v-card-actions class="justify-center">
              <v-btn v-if="1 === 2" @click="formNewEmailPassword = true" class="white--text" color="black">Crear Nueva
                Cuenta</v-btn>
              <v-btn @click="submit" class="white--text" color="#1baefc">G Login</v-btn>
            </v-card-actions>

            <v-divider class="ma-3"></v-divider>

            <!-- <div class="pb-3 font-weight-light" align="center">
              <a @click="forgotIngresa=true" href="#">Olvidé mi contraseña</a>
            </div> -->
            <!-- <div class="pb-3 font-weight-light" align="center"> -->
            <!-- <a @click="verifyIngresa=true" href="#">Re enviar mail de verificación</a> -->
            <!-- </div> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="formNewEmailPassword" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Crea Una Nueva Cuenta</v-card-title>
        <v-card-text>Ingresa el correo electrónico que vas a registrar y elige un password
          para tu cuenta</v-card-text>

        <v-spacer></v-spacer>
        <!-- <v-btn color="green darken-1" text @click="ocultador=false">Entendido</v-btn> -->
        <v-card-text>
          <v-form ref="form1" v-model="valid" :lazy-validation="lazy">
            <v-text-field v-model="form.email" label="Email" name="Email" prepend-icon="mdi-email" type="email"
              color="#a51011" required :rules="emailRules" />

            <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password"
              v-model="form.password" color="#a51011" :rules="standarText" required />

            <v-text-field id="nombre" :label="`Nombre de Usuari@`" name="nombre" prepend-icon="mdi-account "
              v-model="form.nombre" color="#a51011" required :rules="standarText" />

            <v-text-field type="number" maxlength="4" prepend-icon="mdi-phone" id="telefono" :label="`Teléfono Móvil`"
              name="Telefono Celular" v-model="form.telefono" color="#a51011" required counter="8" prefix="+569"
              :rules="phoneRules" />
          </v-form>
          <v-alert v-if="errorCreateEmailAccount != null" class="white--text" color="error">
            {{ errorCreateEmailAccount }}
            <br />
            <v-btn @click="resetForm">Cerrar</v-btn>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" text @click="formNewEmailPassword = false">Cancelar</v-btn>
          <v-btn color="green darken-1" text @click="newAccountEmail">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="forgotIngresa" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Recuperación de Password</v-card-title>
        <v-card-text>Ingresa el correo electrónico que registraste para poder recuperar la
          contraseña. Te enviaremos las instrucciones de inmediato</v-card-text>
        <v-card-actions>
          <!-- <v-btn color="green darken-1" text @click="ocultador=false">Entendido</v-btn> -->

          <v-text-field v-model="form.email" label="Email" name="Email" prepend-icon="mdi-account" type="email"
            color="#a51011" />
        </v-card-actions>
        <div class="text-center pb-4">
          <v-btn color="info" text @click="forgotIngresa = false">Cancelar</v-btn>

          <v-btn color="green darken-1" text @click="forgot">Enviar</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="verifyIngresa" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">Validación de Correo Electrónico</v-card-title>
        <v-card-text>Ingresa el correo electrónico que registraste para poder re enviar tu
          correo de verificación. Te enviaremos las instrucciones de
          inmediato</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="ocultador=false">Entendido</v-btn> -->
          <v-text-field v-model="form.email" label="Email" name="Email" prepend-icon="mdi-account" type="email"
            color="#a51011" />
          <v-btn color="info" text @click="verifyIngresa = false">Cancelar</v-btn>
          <v-btn color="green darken-1" text @click="resendVerification()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorForgot" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>Se ha producido un error en la recuperación de la
          contraseña</v-card-text>
        <v-card-subtitle>
          <strong>Detalle:</strong>
          {{ errorForgotMsg }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn color="info" text @click="errorForgot = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="okEnvio" max-width="500">
      <v-card>
        <v-card-title class="headline">Recuperación de Contraseña</v-card-title>
        <v-card-text>Hemos enviado un correo con instrucciones para recuperar tu
          contraseña.</v-card-text>

        <v-card-actions>
          <v-btn color="info" text @click="okEnvio = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogoGenerico" max-width="500">
      <v-card>
        <v-card-title class="headline">{{
          tituloDialogoGenerico
        }}</v-card-title>
        <v-card-text>{{ textoDialogoGenerico }}</v-card-text>

        <v-card-actions>
          <v-btn color="info" text @click="dialogoGenerico = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ textSnack }}
      <v-btn color="pink" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-content>
</template>

<script>
console.log("script");
import firebase from "firebase";
const auth = firebase.auth();
import { mapGetters } from "vuex";
export default {
  // metaInfo: {
  //   title: "Rayo Delivery -  Entregas Rápidas y Seguimiento en Tiempo Real"
  // },
  data() {
    return {
      whitelist:[],
      cargaLogin: false,
      verifyIngresa: false,
      textSnack: "Email enviado correctamente",
      snackbar: false,
      tipoBoolean: null,
      // validate for1
      standarText: [
        (v) => !!v || "Información requerida",
        //  v =>  || "El texto debe tener al menos de 6 caracteres",
        (v) =>
          (v != null && v.length >= 6) ||
          "El texto debe tener al menos de 6 caracteres",
      ],
      // v => !null || "Información requerida (null)",
      // v => !v || "Información requerida",
      // [
      //   v => !!v || "Password is required",
      //   this.password === this.confirmPassword || "Password must match"
      // ],
      // v => !!v || "Información requerida",

      // v => v.length >= 5 || "El texto debe tener al menos de 5 caracteres"

      emailRules: [
        (v) => !!v || "Email es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "El formato del email debe ser valido (xyz@abc.com)",

        // v.match(
        //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     ) || "El formato del email debe ser valido (xyz@abc.com)"
      ],
      phoneRules: [
        (v) =>
          !!v || "El teléfono es necesario para dar seguimiento a tu pedido",
        (v) =>
          (v != null && v.length == 8) ||
          "El teléfono debe tener 8 números después de +569.",

        // v.match(
        //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     ) || "El formato del email debe ser valido (xyz@abc.com)"
      ],
      textoDialogoGenerico: "",
      tituloDialogoGenerico: "",
      dialogoGenerico: false,
      valid: false,
      showCargando: false,
      lazy: true,
      // validate for1
      errorCreateEmailAccount: null,
      formNewEmailPassword: false,
      errorForgot: false,
      okEnvio: false,
      errorForgotMsg: "",
      forgotIngresa: false,
      form: {
        tipoOk: "",
        rut: "",
        email: "",
        password: "",
        clienteRayo: "",
        nombre: "",
        vertical: "",
      },
      error: null,
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
    tipoOk: function () {
      if (this.tipoBoolean == 0) {
        return "Empresa";
      } else {
        return "Persona";
      }
    },
  },




  watch: {
    "$store.state.user.data": {
    
      handler: async function (nv) {        
        let newScope = this
        console.log(newScope.whitelist)
        let arregloLocal = await newScope.checkDataSeguridad();
        console.log(arregloLocal)
       console.log(newScope.whitelist)

        if (newScope.$store.state.user.data != null && newScope.$store.state.user.data.tipoUsuario == "otro") {
  
        console.log("loggedin", newScope.$store.state.user.data.email)      

   
          if( newScope.whitelist[0]!= undefined &&  newScope.whitelist.some(value => value.email === newScope.$store.state.user.data.email) ) {
          console.log("incluyesssssss....")
          
          newScope.$router.push({ name: "asignacionesWarehouse" });
         } 
         else {
          newScope.tituloDialogoGenerico = "No Autorizado"
         newScope.textoDialogoGenerico = "Para más información comunícate con Rayo"
         newScope.dialogoGenerico = true
         firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("session closed");
          newScope.drawer = false;

          if (newScope.$router.currentRoute.name == "login") {
            console.log("stay here");
          } else {
            newScope.$router.push({
              name: "login",
            });
          }
        });        
        //  alert("Not Authorized")
         
         }

       


          //
        } else {

          //es rayo
          
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken;
          // The signed-in user info.
          console.log(newScope.$store.state.user.data)
          // var user = result.user;
          // console.log(user)

          // alert("a ingreso")
          newScope.$router.push({ name: "ingresos" });

        }


      },
      deep: true,
      immediate: false // provides initial (not changed yet) state
    }



  },



  // watch: {
  //   user(auth) {
  //     if (auth) {
  //       console.log("logged in ok");
  //       this.$router.push({ name: "ingreso" });
  //     } else {
  //       console.log("not logged");
  //     }
  //   }
  // },
  async mounted() {
  
    //this.checkDataSeguridad()

  }
    ,

  methods: {

   async  checkDataSeguridad() {
    const newScope = this
   // alert("getting wl list")
  const whitelistRef = firebase.firestore().collection('whitelistEpasWarehouse');
//const snapshot = await whitelistRef.where('active', '==', true).get();
const snapshot = await whitelistRef.get();
if (snapshot.empty) {
  console.log('No matching documents.');
  return;
}  
console.log(newScope.whitelist)
snapshot.forEach(doc => {
  // alert("got it wl list")
  console.log(doc.id, '=>', doc.data());
  newScope.whitelist.push(doc.data())
  console.log(newScope.whitelist)
  return newScope.whitelist
});

    },

    softRedirect() {
      var newScope = this;
      setTimeout(function () {
        newScope.$router.push({ name: "notverified" });
      }, 6000);
    },
    resetForm() {
      this.$refs.form1.reset();
      this.formNewEmailPassword = false;
      this.errorCreateEmailAccount = null;
    },

    signinGoogle() {
      console.log("inicio 2")
      firebase.auth().languageCode = "es";
      var provider = new firebase.auth.GoogleAuthProvider();
      var newScope = this;
      newScope.cargaLogin = true;

      // firebase
      //   .auth()
      //   .signOut().then(() => {


      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          newScope.cargaLogin = false;

          // console.log(user)
          // let tipoUsuario =
          //   result.user.email.substr(result.user.email.length - 11) != "rayoapp.com"
          //     ? "epa"
          //     : "rayo";
          // console.log(newScope.$store.state)

          // ...
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          // var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log(credential);
          console.log(error);
          console.log(errorMessage);

          // ...
        });
      // })
    },
    submit() {
      this.cargaLogin = true;
      var newScope = this;
      this.signinGoogle();

      /*
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("LOGIN-sesion closed");
          //    newScope.$store.dispatch("fetchUser", null);
        })
        .then(() => {
          // console.log("here2");

          firebase
            .auth()
            .signInWithEmailAndPassword(this.form.email, this.form.password)
            .then((data) => {
              console.log("LOGIN- EMAIL VERIFIED:" + data.user.emailVerified);
              // newScope.$router.push({ name: "ingreso" });
              newScope.cargaLogin = false;
            })
            .catch((err) => {
              if (
                err.message ==
                "There is no user record corresponding to this identifier. The user may have been deleted."
              ) {
                this.error =
                  "El password ingresado no es correcto o el usuario no existe ";
              } else {
                this.error = err.message;
              }
              console.log(err);
            });

        

        });
        */
    },
    resendVerification() {
      console.log("here 2");
      var newScope2 = this;
      firebase.auth().languageCode = "es";
      console.log(firebase.auth());
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(function () {
          newScope2.textSnack =
            "El email de verificación se ha enviado correctamente";
          newScope2.snackbar = true;
        })
        .catch(function (error) {
          console.log("error en email validación");
          console.log(error);
          newScope2.textSnack =
            "El email de verificación se ha enviado correctamente" +
            error.message;
          newScope2.snackbar = true;
        });
    },

    forgot() {
      console.log("here 2");
      var newScope2 = this;
      firebase.auth().languageCode = "es";
      firebase
        .auth()
        .sendPasswordResetEmail(newScope2.form.email)
        .then(function () {
          newScope2.forgotIngresa = false;
          newScope2.okEnvio = true;
          console.log("mail enviado");
          // Email sent.
        })
        .catch(function (error) {
          console.log(error);
          newScope2.forgotIngresa = false;
          newScope2.errorForgot = true;
          newScope2.errorForgotMsg = error.message;
          // An error happened.
        });
    },
    legacy(uuuid, eeemail) {
      console.log("ini launch");
      //this.$router.push({ name: "ingreso" });
      this.showCargando = true;
      // legacy
      //var pedidosCompany = firebase.firestore().collection("pedidosCompany"); // Get a reference to the PedidosCOmpany collection

      if (uuuid != undefined && uuuid != null) {
        var in1 = this;
        var userRef = firebase.firestore().collection("portalUsers").doc(uuuid);
        var usersRef = firebase.firestore().collection("portalUsers");

        // var geti = userRef
        //   .get()
        //   .then(doc => {
        //     if (!doc.exists) {
        //       console.log("Usuario no existe en database");
        console.log("insertando");

        var datosInsertar = {
          userPagoPendiente: false,
          uid: uuuid,
          displayName: this.form.nombre,
          email: eeemail,
          phone: this.form.telefono,
          tags: ["base"],
          //todo acá debemos  buscar dinmicanete en callback el perfil empresa
          perfilEmpresa: "340pszoCBOFrJK1nX50D",
          //todo acá debemos buscar dinmicanete en callback el perfil empresa
          templatePickup: "",
          templateDelivery: "",
          tipo: false,
          rut: "",
          photoURL: "",
          clienteRayo: this.form.nombre,
          role: "",
          perfil: "propios",
          perfil2: "",
          active: true,
          cecos: [],
          puedeCrearCecos: false,
          companyInfo: {
            companyName: this.form.nombre,
            companyVertical: "",
            companyContactName: "",
            companyContactEmail: "",
            companyContactPhone: "",
            companyAddress: "",
          },
          cecoBase: "base",

          uid: uuuid,
        };
        usersRef
          .doc(uuuid)
          .set(datosInsertar)
          .then(function () {
            in1.showCargando = false;
            console.log("Document successfully written!");
            in1.$store.dispatch("fetchUserDatos", datosInsertar);

            in1.showCargando = false;
            in1.alertaGenerica(
              "Usuario creado",
              "Tu usuario se ha creado correctamente. Bienvenid@ a Rayo. Por favor revisa el correo electrónico que acabamos de enviar para que puedas verificar que tenemos un medio válido para comunicarnos contigo."
            );

            var userFirebase = firebase.auth().currentUser;

            userFirebase
              .sendEmailVerification()
              .then(function () {
                console.log("email validación enviado");
                in1.snackbar = true;
                in1.softRedirect();
              })
              .catch(function (error) {
                console.log("error en email validación");
                console.log(error);
              });
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
            in1.alertaGenerica(
              "Algo salió mal...",
              "Tu usuario no se ha podido crear en nuestros sistemas. Esto puede deberse a que tu correo ya figura en nuestros sistemas. Por favor consulta con el equipo de soporte en el chat de la esquina inferior derecha.</br>"
            );
          });
        // } else {
        //   // console.log('Document data:', doc.data())
        //   console.log("usuario existe");

        //   const datosUser = snapshot.data();
        //   this.$store.dispatch("fetchUserDatos", doc.data);

        // }
        // })
        // .catch(err => {
        //   console.log("Error getting document", err);
        // });
        // legacy
        // } else {
        //   console.log("error en legacy");
      }
    },
    alertaGenerica(titulo, texto) {
      this.textoDialogoGenerico = texto;
      this.tituloDialogoGenerico = titulo;
      //this.formNewEmailPassword = false;
      this.dialogoGenerico = true;
    },
    newAccountEmail() {
      this.formNewEmailPassword = false;
      if (this.$refs.form1.validate()) {
        // this.formNewEmailPassword = false;
        this.showCargando = true;
        var newScope2 = this;
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then((data) => {
            console.log(data);
            console.log("éxito registro en firebase");
            newScope2.legacy(data.user.uid, this.form.email);
          })
          .catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(error);
            newScope2.showCargando = false;
            newScope2.errorCreateEmailAccount = error.message;
            newScope2.alertaGenerica("Se ha producido un error", error.message);
            // ...
          });
      } else {
        console.log("errores de validación del formulario");
      }
    },
  },
};
</script>
